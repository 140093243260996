;(function () {
  const cognito_url = 'https://human.auth.us-east-1.amazoncognito.com'
  const client_id = '463ig7pv31u6kg95a7brl32coc'
  const publishable_key =
    'pk_live_51Pidz02LYlLTijpBnUHEAjp8hG9JlSWhgqrGFEKdAgWndDnG2KxbkT3FQwpg7AHg6hM4Y7gJF8tu2gjTlcrvx8RF00MhodnFKi'
  const checkout_path = '/checkout/'
  const buy_button_id = 'buy_btn_1QkrDO2LYlLTijpBqB4zoxqI'
  const demo_path = '/protein-demo-form/'

  function buyButtonId() {
    return buy_button_id
  }

  function checkoutUrl() {
    return (window.location.protocol + '//' + window.location.host + checkout_path)
  }

  function demoURL() {
    return (window.location.protocol + '//' + window.location.host + demo_path)
  }

  function dec2hex(dec) {
    return ('0' + dec.toString(16)).substr(-2)
  }

  function generateRandomString() {
    let array = new Uint32Array(56 / 2)
    window.crypto.getRandomValues(array)
    return Array.from(array, dec2hex).join('')
  }

  function base64urlencode(a) {
    let str = ''
    let bytes = new Uint8Array(a)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i])
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  }

  function createHash(v) {
    const encoder = new TextEncoder()
    const data = encoder.encode(v)
    return window.crypto.subtle.digest('SHA-256', data).then(function (hashed) {
      return base64urlencode(hashed)
    })
  }

  function isDemo() {
    return typeof LOGIN_MODE !== "undefined" && LOGIN_MODE === "DEMO"
  }

  function showLoginLink() {
    const verifier = generateRandomString()
    const nonce = generateRandomString()
    const redirectURI = isDemo() ? demoURL() : checkoutUrl()
    createHash(verifier).then(function (challenge) {
      sessionStorage.setItem('verifier-' + nonce, verifier)
      document.getElementById('client_id').value = client_id
      document.getElementById('code_challenge').value = challenge
      document.getElementById('state').value = nonce
      document.getElementById('redirect_uri').value = redirectURI

      document.getElementById('identity_provider').value = 'SignInWithApple'
      document.getElementById('response_type').value = 'CODE'
      document.getElementById('scope').value = 'email openid'

      let form = document.getElementById('login_form')
      form.action = cognito_url + '/oauth2/authorize'
      form.style.visibility = 'visible'
    })
  }

  function getUserInfo(token) {
    let req = new XMLHttpRequest()
    req.open('GET', cognito_url + '/oauth2/userInfo', false)
    req.setRequestHeader('Authorization', 'Bearer ' + token)
    req.send(null)

    if(isDemo()){
      tryFetchUserInfo(token)
    }

    return JSON.parse(req.responseText)
  }

  // Test code
  function tryFetchUserInfo(token) {
    const demoInfoUrl = 'https://demo-test.wellprincipled.com/authenticateduser/userWithGroups'
    let req = new XMLHttpRequest()
    req.open('GET', demoInfoUrl, false)
    req.setRequestHeader('Authorization', 'Bearer ' + token)
    req.send(null)
    console.log(JSON.parse(req.responseText))
  }

  function authorize(state, verifier, code) {
    let req = new XMLHttpRequest()
    const redirectURI = isDemo() ? demoURL() : checkoutUrl()

    req.open('POST', cognito_url + '/oauth2/token', false)
    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    req.send(
      'grant_type=authorization_code' +
        '&client_id=' +
        client_id +
        '&code=' +
        code +
        '&code_verifier=' +
        verifier +
        '&redirect_uri=' +
        redirectURI
    )
    let response = JSON.parse(req.responseText)
    return response.access_token
  }

  function showBuyButton(userInfo) {
    let button = document.createElement('stripe-buy-button')
    button.setAttribute('data-rewardful', '')
    button.setAttribute('customer-email', userInfo.email)
    button.setAttribute('customer-reference-id', userInfo.username)
    button.setAttribute('buy-button-id', buyButtonId())
    button.setAttribute('publishable-key', publishable_key)
    let div = document.getElementById('buy_div')
    div.appendChild(button)
    div.style.visibility = 'visible'
  }

  function initAtlas(userInfo) {
    try {
      window.Atlas.call('identify', {
        userId: userInfo.sub,
        name: userInfo.username,
        email: userInfo.email,
      })
    } catch (err) {
      console.error(err)
    }
  }

  function processRequest(state, verifier, code) {
    const access_token = authorize(state, verifier, code)
    const userInfo = getUserInfo(access_token)
    initAtlas(userInfo)
    showBuyButton(userInfo)
  }

  const params = new URLSearchParams(window.location.search)
  const state = params.get('state')
  if (state) {
    // If state is set, assume the user has logged in.
    const verifier = sessionStorage.getItem('verifier-' + state)
    if (verifier) {
      sessionStorage.removeItem('verifier-' + state)
      const code = params.get('code')
      processRequest(state, verifier, code)
    } else {
      console.log('error')
      window.location = checkoutUrl()
    }
  } else {
    // State not set; just show the link.
    showLoginLink()
  }
})()
